<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Regulatory Compliance Audits" size="large" />
    <div class="flex flex-wrap" style="max-width: 1150px;">
      <div class="w-full mb-6 ">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph
            ><p>
              Our comprehensive approach to process safety management can be
              applied to any industrial process and tailored to include all
              applicable regulatory elements. Our uncompromising commitment to
              safety led to the development and successful implementation of
              process safety management practices long before such practices
              became the focus of government regulation.
            </p>
            <p>
              SMS personnel understand the appropriate interpretation and
              application of DoD, DOT, OSHA, and EPA regulations. The
              regulations of OSHA 29 CFR 1910.119 “Process Safety Management”
              and EPA 40 CFR Part 68 “Risk Management Programs” reflect our
              philosophy and approach developed and implemented since 1958. SMS
              can assist your company or organization in complying with these
              and other OSHA, EPA, DoD, and DOT regulations through a
              coordinated, cost-effective team approach. Additionally, SMS
              offers training courses on Process Safety Management and Risk
              Management Programs.
            </p>
            <p>
              Our comprehensive approach can be applied to any process in any
              industry and can be tailored to include any or all of the OSHA and
              EPA elements with which you need help.
            </p>
            <ul class="links">
              <li class="page_item page-item-432">
                <router-link
                  style="color:#aa222c;"
                  to="/risk-management/regulatory-compliance-audits/process-safety-management"
                  >Process Safety Management</router-link
                >
              </li>
              <li class="page_item page-item-436">
                <router-link
                  style="color:#aa222c;"
                  to="/risk-management/regulatory-compliance-audits/risk-management-programs"
                  >Risk Management Programs</router-link
                >
              </li>
            </ul>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Regulatory Compliance Audits",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          'SMS can provide a comprehensive audit for all regulatory requirements from the DoD, DOT, OSHA, and EPA. SMS is familiar with OSHA 20 CFR 1910.119 "Process Safety Management" and EPA 40 CFR Part 68 "Risk Management Programs".'
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
